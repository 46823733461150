import React, { useRef } from 'react';
import './CRUD.css';
import useAddressSuggestions from './useAddressSuggestions';

const ensureHttpsProtocol = (url) => {
  if (!url) return '';
  return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
};

const ItemList = ({ items, editingUuid, setEditingUuid, editItem, setEditItem, saveItem, deleteItem }) => {
  const {
    addressSuggestions,
    showSuggestions,
    handleAddressInputChange,
    setShowSuggestions,
    setAddressSuggestions
  } = useAddressSuggestions();

  // Create a ref map to store refs for each item
  const itemRefs = useRef({});

  const handleEditChange = (e, field, itemId) => {
    setEditItem({ ...editItem, [field]: e.target.value });
  };

  const handleAddressSelect = (suggestion, itemId) => {
    const context = suggestion.context.reduce((acc, ctx) => {
      acc[ctx.id.split('.')[0]] = ctx.text;
      return acc;
    }, {});

    const street = suggestion.text;
    const houseNumber = suggestion.address;
    const city = context.place;
    const country = context.country;
    const zip = context.postcode || '';

    setEditItem({
      ...editItem,
      street: `${street} ${houseNumber}`,
      city: city,
      country: country,
      zip: zip,
      geo_point: {
        lat: suggestion.center[1],
        lon: suggestion.center[0]
      }
    });
    setAddressSuggestions((prev) => ({ ...prev, [itemId]: [] }));
    setShowSuggestions((prev) => ({ ...prev, [itemId]: false }));
  };

  return (
    <div>
      <h2>Items List ({items.length})</h2>
      <table className="item-table">
        <thead>
          <tr>
            <th>Debtor ID</th>
            <th>Name</th>
            <th>Street</th>
            <th>Zip</th>
            <th>City</th>
            <th>Country</th>
            <th>Phone</th>
            <th>Web</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            const isEditing = editingUuid === item.uuid;

            // Create a new ref for the item if it doesn't exist
            if (!itemRefs.current[item.uuid]) {
              itemRefs.current[item.uuid] = React.createRef();
            }

            return (
              <tr key={item.uuid} className={index % 2 === 0 ? 'item-row even' : 'item-row odd'} ref={itemRefs.current[item.uuid]}>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editItem['debtor-id']}
                      onChange={(e) => handleEditChange(e, 'debtor-id', item.uuid)}
                      data-1p-ignore data-bwignore
                      data-lpignore="true"
                    />
                  ) : (
                    item['debtor-id']
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editItem.name}
                      onChange={(e) => handleEditChange(e, 'name', item.uuid)}
                      data-1p-ignore data-bwignore
                      data-lpignore="true"
                    />
                  ) : (
                    item.name
                  )}
                </td>
                <td style={{ position: 'relative' }}>
                  {isEditing ? (
                    <>
                      <input
                        type="text"
                        value={editItem.street}
                        onChange={(e) => {
                          handleEditChange(e, 'street', item.uuid);
                          handleAddressInputChange(e.target.value, item.uuid);
                        }}
                        data-1p-ignore data-bwignore
                        data-lpignore="true"
                      />
                      {showSuggestions[item.uuid] && addressSuggestions[item.uuid]?.length > 0 && (
                        <ul className="suggestions-list">
                          {addressSuggestions[item.uuid].map((suggestion) => (
                            <li key={suggestion.id} onClick={() => handleAddressSelect(suggestion, item.uuid)}>
                              {suggestion.place_name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    item.street
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editItem.zip}
                      onChange={(e) => handleEditChange(e, 'zip', item.uuid)}
                      data-1p-ignore data-bwignore
                      data-lpignore="true"
                    />
                  ) : (
                    item.zip
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editItem.city}
                      onChange={(e) => handleEditChange(e, 'city', item.uuid)}
                      data-1p-ignore data-bwignore
                      data-lpignore="true"
                    />
                  ) : (
                    item.city
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editItem.country}
                      onChange={(e) => handleEditChange(e, 'country', item.uuid)}
                      data-1p-ignore data-bwignore
                      data-lpignore="true"
                    />
                  ) : (
                    item.country
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editItem.phone}
                      onChange={(e) => handleEditChange(e, 'phone', item.uuid)}
                      data-1p-ignore data-bwignore
                      data-lpignore="true"
                    />
                  ) : (
                    item.phone
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editItem.web}
                      onChange={(e) => handleEditChange(e, 'web', item.uuid)}
                      data-1p-ignore data-bwignore
                      data-lpignore="true"
                    />
                  ) : item.web !== "nan" && (
                    <a target="_blank" rel="noreferrer" href={ensureHttpsProtocol(item.web)}>
                      {item.web}
                    </a>
                  )}
                </td>
                <td>
                  {item.geo_point.lat}
                </td>
                <td>
                  {item.geo_point.lon}
                </td>
                <td>
                  {isEditing ? (
                    <>
                      <button onClick={() => saveItem(item.uuid)}>Save</button>
                      <button onClick={() => setEditingUuid(null)}>Cancel</button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => {
                        setEditingUuid(item.uuid);
                        setEditItem(item);
                      }}>Edit</button>
                      <button onClick={() => deleteItem(item)}>Delete</button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ItemList;
