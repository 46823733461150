const poolData = {
    UserPoolId: 'eu-central-1_kYJ9N8lcv',
    ClientId: 'ac9ru4eq5irrhdue8bceer384',
    userPoolDomain: 'tonies-retail-map.auth.eu-central-1.amazoncognito.com'
};


const mapboxConfig = {
    apiKey: process.env.REACT_APP_MAPBOX_TOKEN,
    baseUrl: 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
};
const apiConfig = {
    apiUrl: 'https://q6hkwmbww1.execute-api.eu-central-1.amazonaws.com'
};

export { poolData, apiConfig, mapboxConfig };
