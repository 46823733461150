import React from 'react';
import './CRUD.css'; // Import the CSS file

const SearchItems = ({ searchType, setSearchType, searchQuery, setSearchQuery, handleSearch, handleBackToList }) => {
  return (
    <div className="search">
      <h2>Search Items</h2>
      <div>
        <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
          <option value="name">Name</option>
          <option value="debtor-id">Debtor ID</option>
        </select>
        <input
          type="text"
          placeholder={`Search by ${searchType}`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
        <button onClick={handleBackToList}>Reset Search</button>
      </div>
    </div>
  );
};

export default SearchItems;
