import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { mapboxConfig } from '../../config';

const useAddressSuggestions = () => {
  const [addressSuggestions, setAddressSuggestions] = useState({});
  const [showSuggestions, setShowSuggestions] = useState({});
  const suggestionBoxRef = useRef(null);

  const handleAddressInputChange = async (query, itemId) => {
    if (query.length > 2) {
      try {
        const response = await axios.get(`${mapboxConfig.baseUrl}${encodeURIComponent(query)}.json`, {
          params: {
            access_token: mapboxConfig.apiKey,
            autocomplete: true,
            limit: 10
          }
        });
        setAddressSuggestions((prev) => ({ ...prev, [itemId]: response.data.features }));
        setShowSuggestions((prev) => ({ ...prev, [itemId]: true }));
      } catch (error) {
        console.log('Error fetching address suggestions', error);
      }
    } else {
      setAddressSuggestions((prev) => ({ ...prev, [itemId]: [] }));
      setShowSuggestions((prev) => ({ ...prev, [itemId]: false }));
    }
  };

  const handleClickOutside = (event) => {
    if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
      setShowSuggestions({});
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setShowSuggestions({});
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return {
    addressSuggestions,
    showSuggestions,
    suggestionBoxRef,
    handleAddressInputChange,
    setShowSuggestions,
    setAddressSuggestions
  };
};

export default useAddressSuggestions;
