import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiConfig } from '../../config';
import AddEditItem from './AddEditItem';
import ItemList from './ItemList';
import SearchItems from './SearchItems';
import Popup from './Popup'; // Import the Popup component
import './CRUD.css'; // Import the CSS file

const CRUD = ({ idToken }) => {
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [originalItems, setOriginalItems] = useState([]); // Store original items
  const [paginationKeys, setPaginationKeys] = useState([]); // Stack to hold pagination keys for Previous button
  const [searchType, setSearchType] = useState('name');
  const [searchQuery, setSearchQuery] = useState('');
  const [newItem, setNewItem] = useState({
    'debtor-id': '',
    name: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    phone: '',
    web: '',
    geo_point: { lat: '', lon: '' },
  });
  const [editItem, setEditItem] = useState({
    'debtor-id': '',
    name: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    phone: '',
    web: '',
    geo_point: { lat: '', lon: '' },
  });
  const [editingUuid, setEditingUuid] = useState(null);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility

  useEffect(() => {
    fetchItems();

    // Add Axios interceptor for handling 401 errors
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          setShowPopup(true); // Show popup when 401 error occurs
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const fetchItems = async (startKey = null) => {
    try {
      const response = await axios.get(`${apiConfig.apiUrl}/prod/items`, {
        headers: { Authorization: idToken },
        params: {
          limit: 10,
          startKey: startKey || ""
        }
      });
      setItems(response.data.items);
      setOriginalItems(response.data.items); // Save original items
      setTotalItems(response.data.total); // Assuming the API returns the total count

      if (startKey) {
        setPaginationKeys([...paginationKeys, startKey]);
      } else {
        setPaginationKeys([null]);
      }

      setLastEvaluatedKey(response.data.lastEvaluatedKey || null); // Update the last evaluated key
    } catch (error) {
      console.log('Error fetching items', error);
    }
  };

  const searchItems = async (startKey = null) => {
    try {
      const response = await axios.get(`${apiConfig.apiUrl}/prod/search`, {
        headers: { Authorization: idToken },
        params: {
          [searchType]: searchQuery || "",
          startKey: startKey || ""
        }
      });
      setItems(response.data.items);
      setTotalItems(response.data.total); // Assuming the API returns the total count

      if (startKey) {
        setPaginationKeys([...paginationKeys, startKey]);
      } else {
        setPaginationKeys([null]);
      }

      setLastEvaluatedKey(response.data.lastEvaluatedKey || null); // Update the last evaluated key
    } catch (error) {
      console.log('Error searching items', error);
    }
  };

  const addItem = async () => {
    try {
      await axios.post(`${apiConfig.apiUrl}/prod/items`, newItem, {
        headers: { Authorization: idToken }
      });
      fetchItems();
      setNewItem({
        'debtor-id': '',
        name: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        phone: '',
        web: '',
        geo_point: { lat: '', lon: '' },
      });
    } catch (error) {
      console.log('Error adding item', error);
    }
  };

  const updateItem = async (uuid) => {
    try {
      await axios.put(`${apiConfig.apiUrl}/prod/items/${uuid}`, editItem, {
        headers: { Authorization: idToken }
      });
      fetchItems();
      setEditItem({
        'debtor-id': '',
        name: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        phone: '',
        web: '',
        geo_point: { lat: '', lon: '' },
      });
      setEditingUuid(null);
    } catch (error) {
      console.log('Error updating item', error);
    }
  };

  const deleteItem = async (item) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the item with Debtor ID: ${item['debtor-id']} and Name: ${item.name}?`);
    if (confirmDelete) {
      try {
        await axios.delete(`${apiConfig.apiUrl}/prod/items/${item.uuid}`, {
          headers: { Authorization: idToken }
        });
        fetchItems();
      } catch (error) {
        console.log('Error deleting item', error);
      }
    }
  };

  const handleNext = () => {
    searchQuery ? searchItems(lastEvaluatedKey) : fetchItems(lastEvaluatedKey);
  };

  const handlePrevious = () => {
    const previousKey = paginationKeys[paginationKeys.length - 2];
    setPaginationKeys(paginationKeys.slice(0, -1));
    searchQuery ? searchItems(previousKey) : fetchItems(previousKey);
  };

  const handleSearch = () => {
    searchItems();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleLogout = () => {
    // Implement your logout logic here
    console.log('User logged out');
  };

  const handleBackToList = () => {
    setItems(originalItems); // Revert to original items
    setSearchQuery(''); // Clear search query
  };

  return (
    <div>
      <Popup show={showPopup} onClose={handleClosePopup} message="Your session has expired. Please reload the page." title="Session Expired" />
      <h2>Add Item</h2>
      <AddEditItem
        item={newItem}
        setItem={setNewItem}
        onSubmit={addItem}
        addressSuggestions={addressSuggestions}
        setAddressSuggestions={setAddressSuggestions}
      />

      <SearchItems
        searchType={searchType}
        setSearchType={setSearchType}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
        handleBackToList={handleBackToList}
      />

      <ItemList
        items={items}
        editingUuid={editingUuid}
        setEditingUuid={(uuid) => {
          setEditingUuid(uuid);
          const itemToEdit = items.find(item => item.uuid === uuid);
          setEditItem(itemToEdit);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
        saveItem={updateItem}
        deleteItem={deleteItem}
      />

      <div className="pagination">
        <button
          disabled={paginationKeys.length <= 1}
          onClick={handlePrevious}
        >
          Previous
        </button>
        <button
          disabled={!lastEvaluatedKey}
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CRUD;
