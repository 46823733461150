import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { mapboxConfig } from '../../config';
import './CRUD.css'; // Import the CSS file
import Popup from './Popup'; // Import the Popup component

const AddEditItem = ({ item, setItem, onSubmit, addressSuggestions, setAddressSuggestions }) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const suggestionBoxRef = useRef(null);

  const handleAddressInputChange = async (e) => {
    const query = e.target.value;
    setItem({ ...item, street: query });

    if (query.length > 2) {
      try {
        const response = await axios.get(`${mapboxConfig.baseUrl}${encodeURIComponent(query)}.json`, {
          params: {
            access_token: mapboxConfig.apiKey,
            autocomplete: true,
            limit: 10
          }
        });
        setAddressSuggestions(response.data.features);
        setShowSuggestions(true);
      } catch (error) {
        console.log('Error fetching address suggestions', error);
      }
    } else {
      setAddressSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleAddressSelect = (suggestion) => {
    const place = suggestion.place_name;
    const context = suggestion.context.reduce((acc, ctx) => {
      acc[ctx.id.split('.')[0]] = ctx.text;
      return acc;
    }, {});

    const street = suggestion.text;
    const houseNumber = suggestion.address;
    const city = context.place;
    const country = context.country;
    const zip = context.postcode || '';

    setItem({
      ...item,
      street: `${street} ${houseNumber}`, // Combine street name and house number
      city: city,
      country: country,
      zip: zip,
      geo_point: {
        lat: suggestion.center[1],
        lon: suggestion.center[0]
      }
    });
    setAddressSuggestions([]);
    setShowSuggestions(false);
  };

  const handleClickOutside = (event) => {
    if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleSubmit = () => {
    if (!item.geo_point.lat || !item.geo_point.lon) {
      setShowPopup(true);
      return;
    }
    onSubmit();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="form-row">
      <Popup show={showPopup} onClose={handleClosePopup} message="Please fill in the latitude and longitude." title="Mandatory Fields missing" />
      <div className="form-group">
        <label>Debtor ID</label>
        <input
          type="text"
          placeholder='Debtor ID'
          value={item['debtor-id']}
          onChange={(e) => setItem({ ...item, 'debtor-id': e.target.value })}
          data-1p-ignore data-bwignore
          data-lpignore="true"
          required
        />
      </div>
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          placeholder='Name'
          value={item.name}
          onChange={(e) => setItem({ ...item, name: e.target.value })}
          data-1p-ignore data-bwignore
          data-lpignore="true"
          required
        />
      </div>
      <div className="form-group" style={{ position: 'relative' }} ref={suggestionBoxRef}>
        <label>Street</label>
        <input
          type="text"
          placeholder='Street'
          value={item.street}
          onChange={handleAddressInputChange}
          data-1p-ignore data-bwignore
          data-lpignore="true"
          required
        />
        {showSuggestions && addressSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {addressSuggestions.map((suggestion) => (
              <li key={suggestion.id} onClick={() => handleAddressSelect(suggestion)}>
                {suggestion.place_name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="form-group">
        <label>Zip</label>
        <input
          type="text"
          placeholder='Postal Code'
          value={item.zip}
          onChange={(e) => setItem({ ...item, zip: e.target.value })}
          data-1p-ignore data-bwignore
          data-lpignore="true"
          required
        />
      </div>
      <div className="form-group">
        <label>City</label>
        <input
          type="text"
          placeholder='City'
          value={item.city}
          onChange={(e) => setItem({ ...item, city: e.target.value })}
          data-1p-ignore data-bwignore
          data-lpignore="true"
          required
        />
      </div>
      <div className="form-group">
        <label>Country</label>
        <input
          type="text"
          placeholder='Country'
          value={item.country}
          onChange={(e) => setItem({ ...item, country: e.target.value })}
          data-1p-ignore data-bwignore
          data-lpignore="true"
          required
        />
      </div>
      <div className="form-group">
        <label>Phone</label>
        <input
          type="text"
          placeholder='Phone'
          value={item.phone}
          onChange={(e) => setItem({ ...item, phone: e.target.value })}
          data-1p-ignore data-bwignore
          data-lpignore="true"
          required
        />
      </div>
      <div className="form-group">
        <label>Web</label>
        <input
          type="text"
          placeholder='Web'
          value={item.web}
          onChange={(e) => setItem({ ...item, web: e.target.value })}
          data-1p-ignore data-bwignore
          data-lpignore="true"
          required
        />
      </div>
      <div className="form-group">
        <label>Latitude: {item.geo_point.lat}</label>
        <input
          type="hidden"
          value={item.geo_point.lat}
          onChange={(e) => setItem({ ...item, geo_point: { ...item.geo_point, lat: e.target.value } })}
          required
        />
      </div>
      <div className="form-group">
        <label>Longitude: {item.geo_point.lon}</label>
        <input
          type="hidden"
          value={item.geo_point.lon}
          onChange={(e) => setItem({ ...item, geo_point: { ...item.geo_point, lon: e.target.value } })}
          required
        />
      </div>
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default AddEditItem;
